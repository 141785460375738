<template>
  <v-container class="registration-confirmed-container">
    <CategoryTitle :category="category" />
    <div class="d-flex justify-center mt-5">
      <v-btn
        x-large
        depressed
        class="primary white--text rounded-pill"
        min-width="200"
        @click="handleLogin"
        >{{ $t("register.confirmed.doLogin") }}</v-btn
      >
      <!-- <v-btn
        v-if="!isAuthenticated"
        large
        depressed
        class="primary white--text"
        min-width="200"
        @click="handleLogin"
        >{{ $t("register.confirmed.doLogin") }}</v-btn
      >
      <v-btn
        v-else
        large
        depressed
        class="primary white--text"
        min-width="200"
        @click="goToHome"
        >{{ $t("register.confirmed.goToHome") }}</v-btn
      > -->
    </div>
  </v-container>
</template>
<style lang="scss">
.registration-confirmed-container {
  margin-bottom: 40px;
  padding: 35px 0 70px;
  // h1 {
  //   font-size: 50px;
  //   @media #{map-get($display-breakpoints, 'xs-only')} {
  //     font-size: 25px;
  //   }
  // }
  // .msg-body {
  //   @media #{map-get($display-breakpoints, 'xs-only')} {
  //     font-size: 18px;
  //   }
  // }
}
// .card-container {
//   border: 1px solid var(--v-gray-lighten1);
//   padding: 40px;
//   background-color: #fcf7d7;
//   h2 {
//     font-size: 30px;
//     font-weight: 700;
//     color: $primary;
//     line-height: 35px;
//   }
//   p {
//     margin-top: 20px;
//     font-size: 18px;
//     font-weight: 300;
//   }
// }
</style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";

import categoryMixins from "~/mixins/category";
import loginMixins from "~/mixins/login";
import customService from "@/service/customService";
export default {
  name: "RegisterConfirmed",
  mixins: [categoryMixins, loginMixins],
  components: { CategoryTitle },
  data() {
    return {
      isSuccessStatus: false
    };
  },
  methods: {
    async confirm(params) {
      try {
        this.error = null;
        let response = await customService.verifyEmail(params);
        if (response && response.status === 0) {
          this.isSuccessStatus = true;
        } else {
          if (response && response.errors.length) {
            this.error = response.errors[0].error;
          }
        }
      } catch (response) {
        this.response = response;
      }
    },
    async handleLogin() {
      let isLoggedIn = await this.doLogin("OpenCart");
      if (isLoggedIn) {
        this.$router.push({
          name: "Home",
          path: "/"
        });
      }
    },
    async goToHome() {
      if (await this.needAddress()) {
        if (await this.needTimeslot()) {
          this.$router.push({
            name: "Home",
            path: "/"
          });
        }
      }
    }
  },
  created() {
    this.isSuccessStatus = this.$route.params.status === "success";
    if (this.isCordova) {
      this.confirm(this.$route.query);
    }
  }
};
</script>
